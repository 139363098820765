@import "../../styles/constants.scss";

.salesPage {
  .salesPageContainer {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-breakpoint-3) {
      overflow-x: hidden;
    }
  }
  .dateTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-unit-xxl);

    :global(.card-chart-legend) {
      @media (max-width: $mobile-breakpoint-3) {
        padding: 0 var(--space-unit-md);
        margin: var(--space-unit-md) 0 0 0;
      }
    }

    .dateTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: $mobile-breakpoint-1) {
      padding: 0 var(--space-unit-xl);
    }
  }
  .salesSummary {
    display: flex;

    @media (min-width: $mobile-breakpoint-1) {
      margin: 0 calc(var(--space-unit-md) * -1);
    }

    @media (max-width: $mobile-breakpoint-1) {
      padding: 0 var(--space-unit-xs);
    }

    @media (max-width: $mobile-breakpoint-3) {
      flex-direction: column;
    }
  }

  .chartUpperContainer,
  .timeChartsContainer {
    margin: var(--space-unit-lg) 0;

    @media (max-width: $mobile-breakpoint-3) {
      padding: 0 var(--space-unit-lg);
    }

    @media (min-width: $mobile-breakpoint-1) {
      margin: var(--space-unit-xl) 0;
    }
  }

  .beerSpeciality {
    display: flex;
    align-items: flex-start;
    padding: 0;

    .beerSpecialityChart {
      @media (min-width: $mobile-breakpoint-3) {
        max-width: 40%;
      }
    }

    .beerChart {
      flex: 1;
      margin-left: var(--space-unit-xxl);
    }

    &.mobile {
      flex-direction: column;
      align-items: stretch;

      .beerChart {
        margin-left: 0px;
        margin-top: var(--space-unit-lg);
      }
    }
  }
}

.childCards {
  flex: 1;
  margin: var(--space-unit-md);
  min-width: 190px;
}
.summaryCards {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid var(--color-white);
  position: relative;
  text-align: left;

  .label {
    display: flex;
    align-items: center;
    .summaryCardsTitle {
      margin-left: var(--space-unit-xs);
      color: var(--color-grey-grey100);
      &.comparing {
        color: var(--color-black);
      }
    }
  }
  .salesSummaryTitle {
    display: flex;
    align-items: center;
    color: var(--color-grey-grey100);
    padding-bottom: var(--space-unit-xs);

    button {
      padding-left: var(--space-unit-xs);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .summaryCardsValue {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;

    &.summaryCardsValueGroup {
      line-height: inherit;
    }
    .cardCompareItem {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: flex-start;
      margin-right: var(--space-unit-md);

      .cardLegendColor {
        height: 15px;
        width: 15px;
        border-radius: 3px;
        margin-right: var(--space-unit-xs);
      }

      &.current {
        .cardLegendColor {
          background-color: var(--color-black);
        }
      }
      &.old {
        .cardLegendColor {
          background-color: var(--color-black);
        }
      }
    }
  }

  .summaryCardsTooltip {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.cardLegend {
  display: flex;
  margin: var(--space-unit-lg) 0;
  align-items: center;
}
.cardLegendItem {
  display: flex;
  align-items: center;
  margin: 0 var(--space-unit-xs);

  .cardLegendColor {
    height: 15px;
    width: 15px;
    border-radius: 2px;
    margin: 0 var(--space-unit-xs);
    background-color: var(--color-keg-green);
  }
}
.timeChartsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 calc(var(--space-unit-lg) * -1);
  padding-bottom: var(--space-unit-xl);

  .timeChart {
    min-width: 400px;
    flex: 1;

    &:first-child {
      margin-right: var(--space-unit-xxl);

      @media (max-width: $mobile-breakpoint-1) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: $mobile-breakpoint-1) {
    .timeChart {
      margin-bottom: var(--space-unit-xxl) !important;
    }
  }

  @media (max-width: $mobile-breakpoint-1) {
    margin: 0px;
    margin-bottom: var(--space-unit-xl);
    min-width: unset;

    .timeChart {
      min-width: unset;
      flex-basis: 100%;
      margin: 0px 0px;
    }
  }
}

.salesFaultySensor {
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 1px solid var(--color-alert-alert100);
  padding-left: var(--space-unit-xs);
  cursor: pointer;

  &:hover {
    background-color: var(--color-grey-grey25);
  }
  .faultyIcon {
    margin-left: var(--space-unit-xs);
  }
}

.missingHoursModal {
  max-width: 436px;

  @media (max-width: $mobile-breakpoint-3) {
    max-width: calc(100vw - var(--space-unit-xl));
  }

  .missingHoursModalBody {
    color: var(--color-grey-grey100);
    padding-top: 0;
  }
}
