.info-modal-item-wrapper {
  margin: var(--space-unit-md) 0;

  &:first-child {
    margin-top: 0;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-unit-xxs);

    .icon {
      margin-right: var(--space-unit-sm);
    }
  }
}
