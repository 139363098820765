.title {
  padding-top: var(--space-lg);
}

.chart {
  flex: 1;
  display: flex;
  justify-content: center;

  .comparisonText {
    text-align: center;

    .positive {
      color: var(--color-success-success100);
    }

    .negative {
      color: var(--color-alert-alert100);
    }

    &.loading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .loadingValue,
      .loadingText {
        display: block;
        background-color: var(--color-grey-grey50);
        max-width: 40px;
        border-radius: 1px;
        min-height: 28px;
        width: 100%;
      }

      .loadingText {
        margin-top: var(--space-unit-xs);
        min-height: 26px;
        max-width: 120px;
      }
    }
  }
}

.details {
  display: flex;
  justify-content: center;
  margin-top: var(--space-unit-xxl);
  flex-shrink: 0;

  .detailsItem {
    text-align: center;
    flex: 1;
    max-width: 40%;

    .detailsHeader {
      display: flex;
      padding-right: var(--space-unit-sm);
      align-items: center;
      justify-content: center;

      .yourOutlet,
      .compareOutlet {
        background-color: var(--color-background-black);
        margin-right: var(--space-unit-sm);
        border-radius: 4px;
        height: var(--space-unit-md);
        width: var(--space-unit-md);
        min-width: var(--space-unit-md);
        min-height: var(--space-unit-md);
      }

      .compareOutlet {
        background-color: var(--color-warning-warning100);
      }
    }

    .detailsValue {
      margin: var(--space-unit-xs) 0;
    }
  }

  .divider {
    margin: 0px var(--space-unit-xl);
  }

  &.loading {
    h2,
    span {
      color: transparent;
      background-color: var(--color-grey-grey50);
      border-radius: 1px;
    }

    .detailsHeader {
      .yourOutlet,
      .compareOutlet {
        background-color: var(--color-grey-grey50);
      }
    }
  }
}

.container.tablet {
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  .details {
    margin-left: var(--space-unit-lg);
  }
}
