@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.transform-13 {
  transform: scale3d(1.3, 1.3, 1);
}

.leading-p p {
  line-height: inherit;
}

// Hack class to hide beerDrive volume. Due to the lack of updated information Carlsberg asked to remove that information temporally.
.blank-beer-drive > .tank {
  > span > span {
    @apply hidden;
  }
  > div > div {
    @apply hidden;
  }
  @apply border-grey-grey50 #{!important};
}
