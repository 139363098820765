@import "../../styles/constants.scss";

.mail-unsubscribe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--footer-height));
  flex: 1;

  .span {
    margin-top: var(--space-unit-lg);
  }

  .button {
    margin-top: var(--space-unit-lg);
    padding: var(--space-unit-xs) var(--space-unit-md);
  }
}
