@import "../../styles/constants.scss";

.kegsInfo {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: $mobile-breakpoint-1) {
    margin-bottom: var(--space-unit-xxl);
  }

  .kegsInfoTitle {
    align-items: center;
    display: flex;
    margin-bottom: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-3) {
      margin-left: var(--space-unit-lg);
    }

    > button {
      margin-left: var(--space-unit-xs);
      padding: 0;
    }
  }
}
.card {
  @media (max-width: $mobile-breakpoint-3) {
    margin: 0 var(--space-unit-lg);

    .header {
      margin: 0px var(--space-unit-lg);

      .title {
        margin: 0px !important;
      }
    }

    .kegInfoItem {
      flex: 1;
      margin-bottom: var(--space-unit-lg);
      margin-right: var(--space-unit-md);
    }
  }

  .kegInfoBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .kegInfoItem {
    text-align: left;
  }

  .hardwareIssues {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .hardwareIssuesContainer {
      display: flex;
      flex-shrink: 0;
    }

    .hardwareIssuesMessage {
      margin: 0 var(--space-unit-xs);
    }

    @media (max-width: $mobile-breakpoint-1) {
      padding: var(--space-unit-sm);
      justify-content: center;
    }

    @media (max-width: $mobile-breakpoint-3) {
      .knowMoreButton {
        align-self: flex-end;
      }
    }
  }

  .kegsInfoFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile-breakpoint-1) {
      padding: var(--space-unit-sm);
    }

    > * {
      margin-right: var(--space-unit-xs);
    }
  }

  .info {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 180px;
    min-width: 205px;
    padding: var(--space-unit-xl);

    .number {
      font-size: 21px;
    }

    .status {
      color: var(--color-grey-grey100);
      font-weight: bold;
    }

    .icon {
      height: 32px;
      width: 32px;

      &.mobile {
        display: none;
      }
    }
  }

  .desc {
    flex: 1 1;
    color: var(--color-grey-grey100);
    text-align: right;
    white-space: nowrap;

    @media (max-width: $mobile-breakpoint-2) {
      text-align: left;
      margin-bottom: var(--space-unit-lg);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .link {
      display: flex;
      padding-right: var(--space-unit-xxl);
      position: relative;
      color: var(--color-primary-outdated);

      .arrow {
        position: absolute;
        right: var(--space-unit-lg);
        transition: right 250ms ease-out;
      }

      &:hover {
        cursor: pointer;
        .arrow {
          right: 0px;
        }
      }
    }
  }
}
