
.bottombar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: var(--bottombar-height);
  z-index: 2;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-grey-grey25);

  .bottombar-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .diana-icon {
      fill: var(--color-grey-grey100);
    }

    .bottombar-item-label {
      color: var(--color-grey-grey100);
      margin-top: var(--space-unit-xs);
    }

    &.selected {
      border: 1px solid var(--color-grey-greenish);

      .diana-icon {
        fill: var(--color-black);
      }

      .bottombar-item-label {
        color: var(--color-black);
      }
    }

    &.disabled {
      pointer-events: none;

      .icon {
        fill: var(--color-grey-grey50);
      }

      .bottombar-item-label {
        color: var(--color-grey-grey50);
      }
    }
  }
}
