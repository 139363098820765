.bg-loading {
  @apply bg-grey-grey50 bg-repeat-y animate-shine;
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(#e7e7e7 20px, transparent 0);
  background-size: 50px 1em, /* highlight */ 100% 1em;
  background-position: -50px 0, /* highlight */ 0 0;
}
