@import "../../../styles/constants.scss";

$target-card-width-desktop: 357px;
$target-card-height-tablet: 188px;
$target-card-height-mobile: 336px;

.target {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: var(--space-unit-xxxl);

  @media (max-width: $mobile-breakpoint-1) {
    margin-bottom: var(--space-unit-lg);
  }

  @media (max-width: $mobile-breakpoint-3) {
    margin-bottom: var(--space-unit-xxl);
  }

  &-title {
    align-items: center;
    display: flex;
    margin-bottom: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-3) {
      margin-left: var(--space-unit-lg);
    }

    > button {
      margin-left: var(--space-unit-xs);
      padding: 0;
    }
  }

  &-tooltip {
    margin-left: var(--space-unit-xs);

    &-section {
      display: flex;
      flex-direction: column;
      padding: var(--space-unit-md) 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &-card-container {
    @media (max-width: $mobile-breakpoint-1) {
      min-height: $target-card-height-tablet;
      height: $target-card-height-tablet;
      min-width: calc(100% - var(--space-unit-xl) * 2);
      width: auto;
      padding-right: var(--space-unit-lg);

      &:first-child {
        padding-left: var(--space-unit-lg);
      }
    }
  }

  &-card {
    box-sizing: border-box;
    position: relative;
    width: $target-card-width-desktop;
    flex: 1;

    @media (max-width: $mobile-breakpoint-1) {
      height: 100%;
      width: auto;
    }

    @media (max-width: $mobile-breakpoint-3) {
      height: $target-card-height-mobile;
      margin: 0 var(--space-unit-lg);
      min-width: unset;
      flex: unset;
    }

    & &-body {
      flex: 1 1 1px; // FF fix for overflow to work
      overflow: auto;
      padding: 0;

      &-overflow {
        background: linear-gradient(180deg, rgba(229, 235, 232, 0) 0%, #e5ebe8 100%);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        bottom: 0;
        height: var(--space-unit-xs);
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    &-wrapper {
      display: flex;
      overflow-x: auto;
      @media (max-width: $mobile-breakpoint-1) {
        margin: 0 calc(-1 * var(--space-unit-lg));
        padding-bottom: var(--space-unit-lg);
      }
    }

    &.target-card--single {
      @media (max-width: $mobile-breakpoint-1) {
        margin-right: 0;
        width: calc(100% - var(--space-unit-lg));
      }
    }

    &.target-card--missing {
      background-color: var(--color-black);
      color: var(--color-white);

      @media (max-width: $mobile-breakpoint-1) {
        height: 200px;
      }

      @media (max-width: $mobile-breakpoint-3) {
        height: $target-card-height-mobile;
      }

      &-wrapper {
        display: flex;
        overflow-x: auto;
      }

      .target-card-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media (max-width: $mobile-breakpoint-1) {
          flex-direction: row;
        }

        @media (max-width: $mobile-breakpoint-3) {
          flex-direction: column;
        }

        .donut {
          margin-top: -60px;

          @media (max-width: $mobile-breakpoint-1) {
            margin-top: 0;
            transform: rotate(-45deg);
          }

          @media (max-width: $mobile-breakpoint-3) {
            margin-top: -70px;
            transform: none;
          }
        }

        .target-card-content {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding: 0 var(--space-unit-lg);

          @media (max-width: $mobile-breakpoint-1) {
            align-items: flex-start;
            text-align: start;
            width: 40%;
          }

          @media (max-width: $mobile-breakpoint-3) {
            align-items: center;
            margin-left: initial;
            text-align: center;
            width: initial;
          }

          .target-card-advice {
            width: 70%;
          }

          .target-card-instructions {
            margin-top: var(--space-unit-lg);

            @media (max-width: $mobile-breakpoint-3) {
              width: 80%;
            }
          }
        }

        .target-card-add-button {
          align-self: center;
          margin-bottom: var(--space-unit-lg);
          margin-top: var(--space-unit-lg);

          @media (max-width: $mobile-breakpoint-1) {
            //margin-right: var(--space-unit-md);
            margin-bottom: 0;
          }

          @media (max-width: $mobile-breakpoint-3) {
            margin-bottom: var(--space-unit-lg);
            margin-top: var(--space-unit-lg);
          }
        }
      }
    }

    &.target-card--unavailable {
      background-color: var(--color-grey-grey25);
      color: var(--color-grey-grey100);

      .target-card-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .donut {
          margin-top: -60px;

          @media (max-width: $mobile-breakpoint-1) {
            margin-top: -100px;
          }

          @media (max-width: $mobile-breakpoint-3) {
            margin-top: -60px;
          }
        }

        .target-card-content {
          align-items: center;
          display: flex;
          width: 60%;

          @media (max-width: $mobile-breakpoint-1) {
            margin-top: var(--space-unit-lg);
            width: 30%;
          }

          @media (max-width: $mobile-breakpoint-3) {
            margin-top: var(--space-unit-xxxl);
            width: 60%;
          }
        }
      }
    }
  }


  @media (max-width: 1500px) and (min-width:$mobile-breakpoint-1) {
      margin-top: var(--space-unit-md);
    .target-card {
      width:100%;
      padding: var(--space-unit-xl);
      min-height:200px;
    }
  }
}

.monthly-target {
  padding: var(--space-unit-lg) var(--space-unit-lg) 0;

  &-header {
    display: flex;
    justify-content: space-between;

    &-divider {
      @media (max-width: $mobile-breakpoint-1) {
        margin-top: var(--space-unit-lg);
      }

      @media (max-width: $mobile-breakpoint-3) {
        margin-top: 0;
      }
    }

    &-details {
      display: flex;
    }

    &-timeleft {
      margin-right: var(--space-unit-md);
    }
  }

  &--missing {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: row;
    }

    @media (max-width: $mobile-breakpoint-3) {
      flex-direction: column;
    }

    &:only-child {
      height: 100%;
      justify-content: center;
      margin: 0;
    }

    .monthly-target-content {
      display: flex;
      flex-direction: column;
      padding: 0 var(--space-unit-lg);

      @media (max-width: $mobile-breakpoint-1) {
        padding: 0;
        text-align: start;
        width: 50%;
      }

      @media (max-width: $mobile-breakpoint-3) {
        padding: initial;
        text-align: center;
        width: 80%;
      }

      .monthly-target--missing-description {
        color: var(--color-grey-grey100);
        margin-top: var(--space-unit-xs);
      }
    }

    .monthly-target-button-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .monthly-target-add-new-button {
      margin-top: var(--space-unit-xxl);

      @media (max-width: $mobile-breakpoint-1) {
        margin-top: 0;
      }

      @media (max-width: $mobile-breakpoint-3) {
        margin-top: var(--space-unit-xxl);
      }
    }

    .monthly-target-add-previous-button {
      margin-top: var(--space-unit-xs);

      @media (max-width: $mobile-breakpoint-1) {
        margin-top: var(--space-unit-sm);
      }

      @media (max-width: $mobile-breakpoint-3) {
        margin-top: var(--space-unit-xs);
      }
    }
  }

  &--single {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;

    .monthly-target-header-divider {
      margin-top: var(--space-unit-lg);
    }

    @media (min-width: $mobile-breakpoint-1) {
      height: initial;
    }

    .target-item {
      flex: 1 1 auto;

      @media (min-width: $mobile-breakpoint-1) {
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &-info {
          align-items: center;
          margin-left: 0;
          margin-top: var(--space-unit-xs);
        }
      }

      &-wrapper {
        height: 100%;
      }
    }
  }
}

.target-item {
  align-items: center;
  display: flex;
  padding: var(--space-unit-lg) 0;

  @media (max-width: $mobile-breakpoint-1) {
    padding: 0;
    width: calc((100% - 1px - var(--space-unit-lg)) / 2);
  }

  @media (max-width: $mobile-breakpoint-3) {
    padding: var(--space-unit-lg) 0;
    width: auto;
  }

  .donut {
    flex-shrink: 0;
    height: 64px !important;
    width: 64px !important;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: row;
      padding: var(--space-unit-lg) 0 var(--space-unit-md);
    }

    @media (max-width: $mobile-breakpoint-3) {
      flex-direction: column;
      padding: initial;
    }

    .target-item-divider {
      @media (max-width: $mobile-breakpoint-1) {
        margin: 0 var(--space-unit-lg);
        height: auto !important;
      }

      @media (max-width: $mobile-breakpoint-3) {
        height: 1px !important;
        margin: 0;
      }
    }
  }

  &-info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--space-unit-lg);
    text-align: start;

    &-expected {
      margin-top: var(--space-unit-xs);

      &--above {
        color: var(--color-success-success100);
      }

      &--below {
        color: var(--color-alert-alert100);
      }
    }
  }
}

.add-target-modal {
  @media (max-width: $mobile-breakpoint-2) {
    max-height: calc(100vh - var(--space-unit-xl));
    max-width: calc(100vw - var(--space-unit-xl));
  }
  @media (max-width: $mobile-breakpoint-3) {
    max-height: 100%;
    max-width: 100%;
  }

  &-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &-input {
    -webkit-appearance: none;
    margin-top: var(--space-unit-lg);
    width: 50%;

    @media (max-width: $mobile-breakpoint-2) {
      width: 90%;
    }
  }

  &-divider {
    margin: var(--space-unit-lg) 0;
  }

  &-speciality-question {
    width: 70%;

    @media (max-width: $mobile-breakpoint-2) {
      width: 100%;
    }
  }

  & &-footer {
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile-breakpoint-3) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }

    .add-target-modal-button {
      @media (max-width: $mobile-breakpoint-3) {
        margin-left: 0;
        margin-top: var(--space-unit-md);
      }
    }
  }
}
