@import "../../../styles/constants.scss";

.profile-menu-title {
  cursor: default !important;
  min-width: 300px;
  padding: var(--space-unit-lg) !important;

  &:hover {
    background-color: var(--color-white) !important;
  }

  > * {
    display: flex;
    flex-direction: column;
  }

  .profile-menu-email {
    color: var(--color-grey-grey100);
  }
}

.profile-menu-logout {
  border-top: 1px solid var(--color-grey25) !important;
  padding: var(--space-unit-xs) var(--space-unit-lg) !important;

  :hover {
    color: var(--color-alert-alert100);
  }
}

.profile-logout-modal {
  .profile-logout-modal-confirm {
    color: var(--color-alert-alert100);
  }
}

@media (max-width: $mobile-breakpoint-1) {
  .profile-open > .profile-portal {
    margin-left: var(--space-unit-xs);
    margin-top: calc(-1 * var(--space-unit-xs));
  }
}

.profile-mobile {
  display: flex;
  flex-direction: column;
  padding: var(--space-unit-lg);
  border: 1px solid var(--color-grey-greenish);
  border-radius: 10px;
  padding-bottom: var(--space-unit-xs);

  &-title {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--space-unit-lg);
    border-bottom: 1px solid var(--color-grey-grey25);
  }
  &-email {
    margin-top: var(--space-unit-xs);
  }
  &-button {
    margin-top: var(--space-unit-md);
  }
}

.sidebar-profile-button {
  padding-left: var(--space-unit-xs);

  @media (min-width: $mobile-breakpoint-1) {
    margin-top: var(--space-unit-xs);
  }
}
