.dates-picker {
  font-family: var(--font-primary);

  .DayPicker__withBorder {
    box-shadow: none;
    border-radius: var(--border-radius);
    border: 1px double var(--color-grey-grey50);
  }
  .CalendarDay__default {
    border: 1px solid var(--color-grey-grey50);
    color: var(--color-black);
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: var(--color-grey-grey25);
    border: 1px double var(--color-grey-grey50);
    color: var(--color-black);
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span {
    background: var(--color-primary-outdated);
    border: 1px double var(--color-primary-outdated);
    color: var(--color-white);
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    color: var(--color-grey-grey50);
  }

  .CalendarDay__blocked_calendar {
    background-color: var(--color-grey-grey25);
    opacity: 0.2;
  }
}
