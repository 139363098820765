.App {
    text-align: center;
    background-color: var(--color-background-white);
    --app-margin: calc(100vw - 100%);
    position: relative;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.ptr--ptr {
    box-shadow: none !important;
}

.ptr--box {
    padding: 0px !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.splash-loading svg {
    max-width: 100%;
}

.root-container {
    height: 100vh;
}

.image-avatar-circle {
    height: calc(100% - var(--space-unit-xs)) !important;
    width: calc(100% - var(--space-unit-xs)) !important;
    color: var(--color-black);
}

.bg-dark-loading {
    @apply animate-shine;
    background-image: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
    background-repeat: repeat;
    background-size: 450px 400px;
}

.tap-svg mask {
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-type: alpha;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: "BrandonGrotesque";
    src: url("./assets/fonts/BrandonGrotesque-Medium.woff2") format("woff2"),
    url("./assets/fonts/BrandonGrotesque-Medium.otf") format("otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "BrandonGrotesque";
    src: url("./assets/fonts/BrandonGrotesque-Regular.woff2") format("woff2"),
    url("./assets/fonts/BrandonGrotesque-Regular.otf") format("otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BrandonGrotesque";
    src: url("./assets/fonts/BrandonGrotesque-Bold.woff2") format("woff2"),
    url("./assets/fonts/BrandonGrotesque-Bold.otf") format("otf");
    font-weight: bold;
    font-style: normal;
}
