@import "../../styles/animations.scss";
@import "../../styles/constants.scss";

.app-parent {
  margin-left: var(--app-margin);
}
.app-container {
  display: block;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  //position: relative;

  @media (max-width: $mobile-breakpoint-2) {
    //overflow: hidden;
    //overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: $mobile-breakpoint-1) {
    height: 100%;
  }
}

.router-container {
  display: flex;
  flex: 1 1;
  //overflow-y: auto;
  .router-inner-container {
    min-height: calc(100vh - var(--footer-height));
    padding-left: var(--sidebar-width);
  }
  &.mobile-router-container {
    .router-inner-container {
      padding: 0;
    }
  }
}

.transition-group {
  width: 100%;
}

.fade-exit {
  animation: fade_out 0.25s forwards;
}
/* TODO fix this with footer and display none */
.fade-enter {
  //display: none;
  opacity: 0;
}

.fade-enter-done {
  animation: fade_in 0.25s forwards;
}
