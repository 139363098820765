:root {
  --color-keg-green: #00321e;
  --color-primary-outdated: var(--color-keg-green);
  --color-link: var(--color-primary-outdated);
}

.dataCurrent {
  background-color: var(--color-black);
}
.dataOld {
  background-color: var(--color-white);
  &.is-compare {
    background-color: var(--color-black);
  }
}
.dataOlder {
  background-color: var(--color-white);
}
