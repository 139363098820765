.modal-error {
  width: 100%;
  max-width: 560px;
  height: 100%;
  max-height: 446px;

  .modal-error-content {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-error-graphic {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      left: 0;
    }

    .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .button-refresh {
    align-self: center;
  }
}

