@import "../../../styles/constants.scss";

.with-agg-container {
  position: relative;
  //overflow: hidden;
  @media (max-width: $mobile-breakpoint-3) {
    width: 100%;
  }

  &.loading {
    opacity: 0.5;
    &:after {
      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.3) 92%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: animateLoading 1.5s infinite ease-in-out;
    }
  }
}

.with-agg-container {
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 1;
    pointer-events: none;
  }
}
/* Active state */
.with-agg-container:active:after {
  opacity: 0;
}
@keyframes animateLoading {
  0% {
    left: -200%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
