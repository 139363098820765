@import "../../../styles/constants";

.card-chart {
  .card-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-title {
      display: flex;
      justify-content: flex-start;
      margin-bottom: var(--space-unit-md);
      align-items: center;
      margin-right: var(--space-unit-xs);

      .tooltip {
        margin-left: var(--space-unit-xs);
      }

      > button {
        margin-left: var(--space-unit-xs);
        padding: 0;
      }
    }
  }

  .card-error-message {
    top: calc(var(--space-unit-xxl) + var(--space-unit-xl));
  }
  .card-content {
    padding-bottom: 0px;

    &.loading {
      min-height: 300px;
      opacity: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .card-legend-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-unit-md);

    @media (max-width: $mobile-breakpoint-3) {
      justify-content: normal;
      margin-top: 0;
      margin-bottom: var(--space-unit-md);
    }
  }
}

.speciality-chart {
  padding-top: var(--space-unit-lg);
  text-align: left;

  @media (max-width: $mobile-breakpoint-3) {
    padding: 0 var(--space-unit-lg) 0 var(--space-unit-lg);
  }

  .speciality-chart-header {
    display: flex;

    > button {
      margin-left: var(--space-unit-xs);
      padding: 0;
    }
  }

  .speciality-chart-body {
    @media (max-width: $mobile-breakpoint-1) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.beer-chart {
  padding-top: var(--space-unit-lg);
  text-align: left;

  @media (max-width: $mobile-breakpoint-3) {
    padding: 0 var(--space-unit-lg) 0 var(--space-unit-lg);
  }

  .beer-chart-title {
    display: flex;
    margin-bottom: var(--space-unit-md);

    > button {
      margin-left: var(--space-unit-xs);
      padding: 0;
    }
  }
}
