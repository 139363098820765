.ddm-scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: var(--space-unit-xs);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-white);
    border: 1px solid var(--color-grey-greenish);
    border-radius: var(--border-radius);
    margin: var(--space-unit-xs);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-grey-greenish);
    border-radius: var(--border-radius);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-grey-grey50);
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
