.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-unit-md);

  .icon {
    cursor: pointer;
    height: var(--space-unit-md);
    width: var(--space-unit-md);
    padding: var(--space-unit-xs);
    margin-right: calc(-1 * var(--space-unit-xs));
  }
}

.notification {
  background-color: var(--color-alert-alert100) !important;
}
