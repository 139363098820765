@import "../../../styles/constants.scss";

.grid {
  line {
    stroke: var(--color-grey-grey25);
  }
  .domain {
    display: none;
  }
}
.axis {
  font-family: var(--font-primary);
  .domain {
    display: none;
  }
  .tick {
    line {
      stroke: none;
    }
    text {
      font-size: 12px;
      font-weight: 500;
      color: var(--color-grey-grey100);
      pointer-events: none;
    }
  }

  &.show-ticks {
    .tick {
      line {
        stroke: #ccc;
        stroke-dasharray: 5 5;
        stroke-width: 2;
      }
    }
  }
  &.y {
    .tick {
      text {
        text-anchor: middle;
        font-size: 12px;
        font-weight: 500;
        color: var(--color-grey-grey100);
      }
    }
  }
}

.x {
  .tick {
    text {
      pointer-events: none;
    }
  }
}

.chart-container {
  position: relative;
}

.custom-domain {
  stroke: var(--color-grey-grey50);
}

.chart-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  padding: var(--space-unit-md);
  border-radius: 10px;
  min-width: 285px;

  .tooltip-date {
    color: var(--color-black);
    margin-bottom: var(--space-unit-md);
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .tooltip-content {
    display: flex;
    color: var(--color-grey-grey100);
    justify-content: space-between;
    box-sizing: border-box;

    .tooltip-divider {
      width: 1px;
      background-color: var(--color-grey-grey25);
      height: 40px;
    }
    .tooltip-inner-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 0 var(--space-unit-xs);
      box-sizing: border-box;
      overflow: hidden;

      .tooltip-title {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: var(--space-unit-xs);
        max-width: 100%;

        &.off-hour {
          margin-bottom: var(--space-unit-xl);
        }
      }
      .tooltip-value {
        color: var(--color-black);
      }

      .tooltip-value-rect {
        height: 10px;
        width: 10px;
        min-width: 10px;
        min-height: 10px;
        border-radius: 2.5px;
        margin-right: var(--space-unit-xxs);

        &.your-outlet {
          background-color: var(--color-black);
        }
        &.compare {
          background-color: var(--color-warning-warning100);
        }
      }

      .off-hour-content {
        display: flex;
        align-items: center;

        .off-hour-value {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &:first-child {
            color: var(--color-black);
            margin-right: var(--space-unit-xs);
            padding-right: var(--space-unit-xs);
            border-right: 1px solid var(--color-grey-grey25);
          }

          .tooltip-value {
            margin-top: var(--space-unit-xxs);
          }
        }
      }
    }
  }
}
