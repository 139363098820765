@import "../../../styles/constants.scss";

.base-layout-container {
  overflow: hidden;
  padding: var(--space-unit-lg);
  padding-bottom: var(--space-unit-xxl);
  @media (max-width: $mobile-breakpoint-3) {
    min-height: 100vh;
  }

  &.base-layout-mobile-overview {
    @media (max-width: $mobile-breakpoint-3) {
      padding: 0px 0px 60px 0px;
    }

    .header {
      margin-bottom: var(--space-unit-xxl);
    }

    .ncu-only {
      .ncu-only-content {
        margin-top: 40px;
        @media (max-width: $mobile-breakpoint-3) {
          margin-top: 0px;
        }
      }
    }
  }

  &.base-layout-mobile-settings {
    @media (max-width: $mobile-breakpoint-3) {
      padding-bottom: 0;
      padding-top: 0;
    }

    .header {
      margin-bottom: var(--space-unit-xl);
      padding-left: 0;
      padding-right: 0;
    }

  }

  &.base-layout-mobile-sales {
    overflow: initial;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    position: relative;

    @media (max-width: $mobile-breakpoint-3) {
      padding: unset;
    }

    @media (max-width: $mobile-breakpoint-1) {
      .header {
        margin-bottom: 0;
      }
    }

    .ncu-only {
      .ncu-only-content {
        margin-top: 64px;

        @media (max-width: $mobile-breakpoint-3) {
          margin-top: 48px;
        }
      }
    }
  }

  &.base-layout-mobile-installation {
    .header {
      @media (max-width: $mobile-breakpoint-3) {
        padding: unset;
      }
    }
  }

  &.base-layout-mobile-product {
    .header {
      @media (max-width: $mobile-breakpoint-3) {
        padding: unset;
      }
    }

    .ncu-only {
      padding: 0 !important;

      .ncu-only-content {
        margin-top: 64px;

        @media (max-width: $mobile-breakpoint-3) {
          margin-top: 48px;
        }
      }
    }
  }
}
