@import "../../../../styles/constants.scss";

.faulty-sensor-modal {
  max-width: 600px;

  @media (max-width: $mobile-breakpoint-3) {
    max-width: calc(100vw - var(--space-unit-xl));
  }

  &-title-icon {
    fill: var(--color-alert-alert100);
  }

  & &-footer {
    padding: var(--space-unit-sm);
    place-content: center;
  }
}
