@import "../../styles/constants.scss";

.sales-header {
  display: grid;
  grid-gap: var(--space-unit-lg);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  @media (min-width: $mobile-breakpoint-1) {
    margin-bottom: var(--space-unit-lg);
  }

  &.mobile {
    flex-direction: column;
    justify-content: center;
    padding: 0px var(--space-unit-lg);
  }

  &.solo-outlet {
    flex: 1;

    .performance-card {
      flex: 1;
      margin: 0 var(--space-unit-sm);

      .performance-card-info {
        justify-content: flex-start !important;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .performance-card.horizontal.tablet {
    margin-bottom: 0;
    margin-right: 0;
    max-width: none !important;

    .performance-card-title-wrapper {
      justify-content: flex-start;
    }

    .performance-card-group {
      width: 50%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}
